import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete,Button, Card, FormHelperText, FormControl, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import Avatar from '@mui/material/Avatar'
import SnackBarUI from '../../common/SnackUI';
import { useNavigate } from 'react-router-dom';
import { uplodImageAction } from '../../redux/toolkit/image/uploadImage';
import { DevTool } from '@hookform/devtools';
import { appCategoryAction } from '../../redux/toolkit/apps/categories';
import AppbarUI from '../../common/AppbarUI'
import BodyWapperUI from '../../common/BodyWapperUI'
import DrawerUI from '../../common/DrawerUI'
import { appStoreAction } from '../../redux/toolkit/apps/store';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const app_schema = yup.object().shape({
  categories: yup.array().min(1).required('categories should not be empty'),
  platform: yup.string().required('Platform is required.'),
  app_color: yup.number().required('App Theme is required.'),
  app_name: yup.string().required('App Name is required.'),
  app_logo: yup.string().required('App logo is required.'),
  currency_name: yup.string().required('Currency Name is required.'),
  currency_logo: yup.string().required('Currency Logo is required.'),
  exchange_rate: yup.number().required('Exchange Rate is required.'),
  postback_url: yup.string().required('Exchange Rate is required.'),

}).required();

export default function CreateApp() {


  const { register, control, setError, watch, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm({
    resolver: yupResolver(app_schema), defaultValues: {}
  });

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [category, setCategory] = useState([])
  const [platform, setPlatform] = useState()
  const [appLogo, setAppLogo] = useState()
  const [currLogo, setCurrLogo] = useState()
  const [theme, setTheme] = useState()
  const [snakbar, setSnakbar] = useState(false)
  const [adsEnabled,setAdsEnabled]=useState(false)
  const appCategory = useSelector(state => state.appCategory)
  const { status, data: appCategoryData, message, loading } = appCategory

  const appLists = useSelector(state => state.appLists)
  const { status: appStatus, data: appData, message: appMsg, loading: appLoad } = appLists


  const appStore = useSelector(state => state.appStore)
  const { status: Status, data: Data, message: Msg, loading: Load } = appStore




  useEffect(() => {
    dispatch(appCategoryAction())
  }, [dispatch])


  
  const categoryArr = appCategoryData

  console.log("categoryArr-->", categoryArr)

  // const categoryArr=[
  //   "hello",
  //   "world"
  // ]

  const platformArr = ["Android", "IOS", "Web"]

  const handleAppSubmit = async (data) => {
    
    const appStore = await dispatch(appStoreAction({ ...data, categories: category, app_logo: appLogo, currency_logo: currLogo, platform: platform, app_color: theme,is_ads_enabled:adsEnabled }))

    setSnakbar(true)

    setTimeout(() => {

      navigate('/apps')
    }, 2000)




  }

  const handleIconUpload = async (e) => {
    const formData = new FormData();

    formData.set("file", e.target.files[0]);

    let uploadedImg = await dispatch(uplodImageAction(formData))
    const imgStr = uploadedImg?.payload?.data?.url;
    console.log("imgStr-->", imgStr)
    setAppLogo(imgStr)
    setValue('app_logo', imgStr, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,

    })
  }

  const handleIconUpload2 = async (e) => {
    const formData = new FormData();

    formData.set("file", e.target.files[0]);

    let uploadedImg = await dispatch(uplodImageAction(formData))
    const imgStr = uploadedImg?.payload?.data?.url;
    console.log("imgStr-->", imgStr)
    setCurrLogo(imgStr)
    setValue('currency_logo', imgStr, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,

    })
  }

  const handleSwitchChange=()=>{
      setAdsEnabled((prev)=>!prev)  
      
  }
  const handleChange = async (e) => {
    setTheme(e.target.value);
    setValue('app_color', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,

    })
  }

  return (
    <>
      <AppbarUI />
      <DrawerUI />
      <BodyWapperUI>
        <Card sx={{ p: 5 }}>
          <Typography variant='h5' sx={{ mb: 5 }}>Create App</Typography>
          <form onSubmit={handleSubmit(handleAppSubmit)}>

            <Stack spacing={3}>

              <Autocomplete
                disablePortal
                multiple
                aria-multiline
                options={categoryArr}
                getOptionLabel={(option) => option.cat_name} // Specify the property to display as the option label
                onChange={(event, value) => {
                  console.log("value in on change-->", value)
                  setCategory(value.map(item => item))
                  setValue('categories', value.map(item => item), {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,

                  })
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} error={errors?.categories?.message} helperText={errors?.categories?.message} label="select Categories" />}
              />

              <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">

                <TextField label="App Name" type='text' {...register('app_name')} error={errors?.app_name?.message} helperText={errors?.app_name?.message} style={{ width: "50%" }} />



                {/* <TextField label="Color Theme" type='color' {...register('app_color')} error={errors?.app_b_color?.message} helperText={errors?.app_b_color?.message} style={{ width: "450px" }} /> */}

                <FormControl style={{ width: "50%" }} error={!!errors?.app_color}>
                  <InputLabel id="demo-simple-select-label" >Color Theme</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={theme}
                    label="App Theme"
                    onChange={handleChange}
                    error={errors?.app_color?.message} helperText={errors?.app_color?.message}

                  >
                    <MenuItem style={{ background: "#22819B" }} value={1}>Theme 1</MenuItem>
                    <MenuItem style={{ background: "#FFCB9B" }} value={2}>Theme 2</MenuItem>
                    <MenuItem style={{ background: "#BEDEBE" }} value={3}>Theme 3</MenuItem>
                    <MenuItem style={{ background: "#fff" }} value={4}>Theme 4</MenuItem>
                    <MenuItem style={{ background: "#070707", color: "#fff" }} value={5}>Theme 5</MenuItem>
                    <MenuItem style={{ background: "#FFBA4D", color: "#fff" }} value={6}>Theme 6</MenuItem>
                  </Select>
                  {errors?.app_color && <FormHelperText>{errors.app_color.message}</FormHelperText>}
                </FormControl>





              </Stack>

              <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">
                <TextField InputLabelProps={{ shrink: true }} label="App logo" type="file" id='app_logo' onChange={handleIconUpload} error={errors?.app_logo?.message} helperText={errors?.app_logo?.message} style={{ width: "50%" }} />
                <Avatar alt="App logo" src={appLogo} />
                {/* <TextField label="Currency Name" {...register('currency_name')} error={errors?.currency_name?.message} helperText={errors?.currency_name?.message} style={{ width: "450px" }} /> */}


                <TextField InputLabelProps={{ shrink: true }} error={errors?.currency_logo?.message} helperText={errors?.currency_logo?.message} label="Currency Logo" type="file" id='curr_logo' onChange={handleIconUpload2} style={{ width: "50%" }} />
                <Avatar alt="App logo" src={currLogo} />



              </Stack>

              <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">



                <TextField label="1Rs=" {...register('exchange_rate')} error={errors?.exchange_rate?.message} helperText={errors?.exchange_rate?.message} style={{ width: "50%" }} />
                <Autocomplete
                  disablePortal
                  options={platformArr}
                  onChange={(event, value) => {
                    setPlatform(value)
                    setValue('platform', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,

                    })
                  }}

                  sx={{ width: '50%' }}

                  renderInput={(params) => <TextField {...params} error={errors?.platform?.message} helperText={errors?.platform?.message}
                    label="select Platform" />}
                />

              </Stack>

              <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">


                <TextField label="Currency Name" {...register('currency_name')} error={errors?.currency_name?.message} helperText={errors?.currency_name?.message} style={{ width: "50%" }} />
                <TextField {...register('postback_url')} error={errors?.postback_url?.message} helperText={errors?.postback_url?.message} label="Post Back Url" style={{ width: "50%" }} />

              </Stack>
              <Stack direction='row' spacing={2}>
              <FormControlLabel  control={<Switch onChange={handleSwitchChange} checked={adsEnabled}/>} label="Ads Enabled" />
              </Stack>
              <Stack direction='row' spacing={2}>
                <Button variant='contained' type='submit'>Create App</Button>
              </Stack>
            </Stack>
          </form>
        </Card>
      </BodyWapperUI>
      {snakbar && <SnackBarUI state={snakbar} setState={setSnakbar} status={Status} message={Msg} />}
      <DevTool control={control} />
    </>
  )
}
