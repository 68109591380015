import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { noOfParticipantsin30DaysAction } from '../../redux/toolkit/dashboard/participantsin30days';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display:false
    },
    title: {
      display: true,
      text: 'Conversion Rate in last 30 days',
    },
    maintainAspectRatio: false, // Set this to false to allow changing the canvas size
    aspectRatio: 2,
    
    
  },
};

const labels = ['0days', '5days', '10days', '15days', '20days', '25days', '30days'];

const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [0,5,10,15,20,45,30,40,50,60],
      borderColor: '#0C6A96',
      backgroundColor: '#fff',
    },
    
  ],
};

function ConversionRate() {
  const dispatch=useDispatch()
  const noOfParticipantsin30Days = useSelector(state => state.noOfParticipantsin30Days)
  console.log("noOfParticipantsin30Days",noOfParticipantsin30Days?.data)
    // const {data} = noOfParticipantsin30Days
    // console.log("line chart",data)

    const data = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: 0,
          borderColor: '#0C6A96',
          backgroundColor: '#fff',
          tension:0.4,
        },
        
      ],
    };

    useEffect(()=>{
        dispatch(noOfParticipantsin30DaysAction())
    },[dispatch])
  return <Line options={options} data={data}/>;
}

export default ConversionRate
