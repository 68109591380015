import React from 'react'
import Logo from '../../assets/logo/favicon.png'

const Header = () => {
  return (
    <div style={{width:"100%",backgroundColor:"#C0A2D9",padding:"10px 10%"}}>
        <div style={{display:"flex",alignItems:"center",gap:"15px"}}>
        <img src={Logo} width="100px" height="100px" alt="logo" />
        <h2>Quizwall</h2>
        </div>
        {/* <div>
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div> */}
    </div>
  )
}

export default Header