import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"


const initialState = { status: null, loading: false, data: [], error: "", message: '' }


export const resetPasswordAction = createAsyncThunk(
    'reset-password',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/reset/password`, {password: data?.password})
            //const res = await axios.post(`/support-server/api/register-admin`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const resetPasswordSlice = createSlice({
    name: 'reset-password',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(resetPasswordAction.pending, (state) => {

                state.loading = true
            })
            builder.addCase(resetPasswordAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(resetPasswordAction.rejected, (state, action) => {
                state.loading = false
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.error = action?.payload?.message
            })
        },

})



export const resetPasswordReducer = resetPasswordSlice.reducer