import React, { useEffect } from 'react';
import { Grid, Stack,TextField } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { rewardLastWeekAction } from '../../redux/toolkit/dashboard/rewardLastWeek';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Users Earning in last 7 days',
    },
    maintainAspectRatio: false, // Set this to false to allow changing the canvas size
    aspectRatio: 2,


  },
};




function UserEarnedChart() {
  const dispatch = useDispatch()
  const totalRewardLastWeek = useSelector(state => state.totalRewardLastWeek)
  console.log("reward", totalRewardLastWeek?.data)
  const labels = [];
  const userCount=[];
  totalRewardLastWeek?.data.map(item=>{
    labels.push(item?._id)
    userCount.push(item?.total_points  )
  })

  // const {data} = noOfParticipantsin30Days
  // console.log("line chart",data)
  const [date, setDate] = React.useState(dayjs());

  const onDateChangeHandler = (date) => {
    setDate(date)


  };

  const submitHandler=(e)=>{

    const startDate=new Date(date.$d)
    startDate.setHours(startDate.getHours() + 5);
    startDate.setMinutes(startDate.getMinutes() + 30);
    const newValue= startDate.toISOString()
    const query={}
    query.date=newValue;
    query.days=e.target.value
    dispatch(rewardLastWeekAction(query))
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: userCount,
        borderColor: '#0C6A96',
        backgroundColor: '#fff',
        tension: 0.4,

      },

    ],
  };

  useEffect(() => {
    const startDate=new Date(date.$d)
    startDate.setHours(startDate.getHours() + 5);
    startDate.setMinutes(startDate.getMinutes() + 30);
    const newValue= startDate.toISOString()
    const query={}
    query.date=newValue;
    query.days=7;
    dispatch(rewardLastWeekAction(query))
  }, [dispatch])
  return (
    <Stack flexDirection={"row"}>
      <Grid item xs={8}><Line options={options} data={data} /></Grid>
      <Grid item xs={4} sx={{display:"flex",flexDirection:"column",padding:"30px"}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
        label="Select Date"
        value={date}
        id="date"
        onChange={onDateChangeHandler}
        renderInput={(params) => <TextField {...params} />}
        
        />
        </LocalizationProvider>
        <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Button value="7" id="seven" onClick={submitHandler}>Last 7 days </Button>
        <Button value="30" id="thirty" onClick={submitHandler}>Last 30 days</Button>
        <Button value="90" id="ninty" onClick={submitHandler}>Last 90 days</Button>
      </ButtonGroup>
      </Grid>
    </Stack>


  );
}

export default UserEarnedChart
