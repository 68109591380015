import { Button, Card,CircularProgress,TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Style from "../../styles/login.module.scss"
import { Link, useNavigate } from 'react-router-dom'
import SnackUI from '../../common/SnackUI';
import { useDispatch, useSelector } from 'react-redux'
import { registerUserAction } from '../../redux/toolkit/auth/registerUser'
import { forgetPasswordAction } from '../../redux/toolkit/auth/forgetpassword'
import { checkOtpAction } from '../../redux/toolkit/auth/checkotp'
import Welcome from '../../assets/img/forgotpasswordd.png'
import ResendEmail from '../../assets/img/resend.png'
import Logo from '../../assets/logo/favicon.png'





export default function ForgetPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [c_password, setCPassword] = useState("")
  const [snack, setSnack] = useState(false)
  const [wait,setWait]=useState(false);
  const [timer,setTimer]=useState(30)
  const [btnTxt,setBtnTxt]=useState('SEND EMAIL')
  


  const otpSelector = useSelector(state => state.forgetpassword)
  const { message, status, loading } = otpSelector


  const handleRegister = async () => {
    const registerData = await dispatch(registerUserAction({ name,email, password,c_password }))
    console.log("registerData", registerData)
    if (registerData?.payload?.status == 200) {
      setTimeout(() => {
        navigate('/login')
      }, 3000)
      //message = "Your account has been created succussfully, Please login now at login screen"
    }
    setSnack(true)
  }

  const sendEmail=async()=>{
    // setTimeout(() => {
    //   setWait(false)
    // }, 30000);
   
    const emailres = await dispatch(forgetPasswordAction({email}))
    if(emailres?.payload.status==200){
      setWait(true)
    }
    
    setSnack(true)
  }

  useEffect(() => {
    let intervalId;
    if (wait) {
      intervalId = setInterval(() => {
        setTimer(prevSeconds => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [wait]);

  useEffect(() => {
    if (timer === 0) {
      setWait(false)
      setTimer(30)
      setBtnTxt('RESEND EMAIL')

      // clearInterval(intervalId);
      // You can add logic here for what to do when the timer reaches zero
    }
  }, [timer]);

  const verifyOtp=async()=>{
    const checkOtpRes = await dispatch(checkOtpAction({email,password}))
    console.log(checkOtpRes?.payload?.token,"checkOtpRes?.payload?.token")
    localStorage.setItem("auth_token", checkOtpRes?.payload?.token)
      navigate("/changepassword")
      window.location.reload()

  } 

  


  return (
    <>
    <Box className={Style.login}>
      <div className={Style.first}>
      <img className={Style.img} src={Welcome} height="50vh" alt="welcome"/>
      <div className='logo__name' style={{ display:"flex",justifyContent:"center",position: "absolute", zIndex: "7", top: "100px", left: "7%",cursor:"pointer" }}>
                <a href='https://www.quizwall.io/' target='_blank'><img src={Logo} width="50px" height="50px" alt="quizwall_logo" /> </a>
                <a style={{textDecoration:"none"}}href='https://www.quizwall.io/' target='_blank'><h2 style={{color:"white"}}>Quizwall</h2></a>
               
            </div>
      <aside style={{position:"fixed",zIndex:"4",bottom:"25px",left:"6%"}}>
            <nav style={{position:"fixed",zIndex:"5",bottom:"50px",left:"4%",display:"flex",flexDirection:"row",alignItems:"center",gap:"5px",color:"#fff"}}>
            <p style={{cursor:"pointer"}} onClick={()=>{navigate('/term-of-use')}}>Terms of Use</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/privacy')}}>Privacy Policy</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/cookie')}}>Cookie Policy</p>
            </nav>
              <p style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>GamingAce Technologies Pvt. Ltd.</p>
          </aside>

      </div>
      <div className={Style.second}>
      {/* <Card className={Style.login_card} sx={{ p: 3 }}> */}
        <Stack spacing={2} sx={{my:5}}>
          <Typography variant='h3'  align='left' fontFamily='Lato' fontSize="37px" fontWeight="600" color='#585858'>Forgot password?</Typography>  
          <Typography sx={{ fontSize: "14px"}} align='left' >Don’t worry.  We can help.</Typography>
        </Stack>
        <Box sx={{mt:2}}> 
          <Stack spacing={2}>
            <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <label fontFamily='Lato' style={{fontSize:"14px",marginBottom:"5px"}}>YOUR REGISTRED EMAIL ADDRESS</label>
                    {/* <img style={{cursor:'pointer'}} onClick={sendEmail} src={ResendEmail} width="127px"/> */}
                </Stack>
                  <input
                    type='email' value={email} onChange={(e) => setEmail(e.target.value)} style={{height:"36px",borderRadius:"5px",padding:"10px"}}                   
                  />
            </Stack>

            <Stack>
            <label fontFamily='Lato' style={{fontSize:"14px",marginBottom:"5px",textTransform:"uppercase"}}>Please fill the 6 digit code which have been sent to your email address</label>
                  <input
                    type='text' value={password} onChange={(e) => setPassword(e.target.value)} style={{height:"36px",borderRadius:"5px",padding:"10px"}}                   
                  />
            </Stack>
            {/* <TextField type='email' value={email} onChange={(e) => setEmail(e.target.value)} variant='outlined' placeholder='Your Registered Email Address' label='Your Registered Email Address' /> */}
            {/* <TextField type='text' value={password} onChange={(e) => setPassword(e.target.value)} variant='outlined' placeholder='Please fill the 6 digit code which have been sent to your email address' label='Please fill the 6 digit code which have been sent to your email address' /> */}
            {/* <Link to='/login' ><Typography variant='subtitle2' align='right' >{`Forgot Password`}</Typography></Link> */}
            <Stack direction="row" spacing={2} sx={{marginBottom:"30px"}}>
                {wait ? <Button disabled={wait?true:false} onClick={sendEmail} sx={{backgroundColor:"rgba(142, 45, 226, 1)",width:"50%",letterSpacing:"3.877px"}} variant='contained'>{`wait(${timer})`}</Button>:
                <Button disabled={email!=""?false:true} onClick={sendEmail} sx={{backgroundColor:"rgba(142, 45, 226, 1)",width:"50%",letterSpacing:"3.877px"}} variant='contained'>{btnTxt}</Button>
                
                }
                <Button disabled={email!=""&&password!=""?false:true} onClick={verifyOtp} sx={{backgroundColor:"rgba(142, 45, 226, 1)",width:"50%",letterSpacing:"3.877px"}} variant='contained'>CONTINUE</Button>
            </Stack>
            <Stack>
            <Typography color="#585858" marginTop="18%" fontSize="21px" fontFamily="lato" variant='subtitle2' align='right'>{`Remembered your Password?`}</Typography>

            <Typography variant='h6' sx={{color:"rgba(142, 45, 226, 1)",fontSize:"1.8em" }} align='right' disabled={loading}><Link style={{textDecoration:"none",width:"100%"}} to='/login' >{loading ? <CircularProgress /> : 'Back To Login'}</Link></Typography>
            </Stack>

        
          </Stack>
        </Box>
      {/* </Card> */}
      </div>
    </Box>
    {snack && <SnackUI state={snack} setState={setSnack} status={status} message={message} />}
    </>
    )
}
