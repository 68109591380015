import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from 'react-router-dom'
import { makeStyles, Theme,} from "@material-ui/core";
import Logo from '../../assets/logo/favicon.png'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '511px',
    height: '340px',
    flexShrink: '0',
    borderRadius: '16px',
    background: '#FFF'
};

// const useStyles = makeStyles((theme: Theme) => ({
//     backDrop: {
//       backdropFilter: "blur(3px)",
//       backgroundColor:'rgba(0,0,30,0.4)'
//     },
//   }));

export default function Popup({state, setState,heading,msg,btnMsg}) {
    const navigate=useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const backToLogin=()=>navigate('/login')

    // const classes = useStyles();
    return (
        <div onClick={backToLogin}>

            <Modal
                open={state}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ border: "2px solid red" }}>
                    {/* <Typography id="modal-modal-title" fontWeight="600" fontSize="14px" textAlign="center" textTransform='uppercase' fontStyle="normal" lineHeight="normal" fontFamily="Poppins">
                        quizwall
                    </Typography> */}
                   <center> <img src={Logo} height="70px" width="70px" alt="logo" /></center>
                    <Typography color='red' textTransform='uppercase'fontStyle="normal" fontWeight="600" lineHeight='162%' fontFamily='lato' fontSize='15px' id="modal-modal-description" textAlign="center" sx={{ mt: 2 }}>
                        {heading || "PASSWORD CHANGED"}
                    </Typography>
                    <Typography color='#444'  fontWeight="400" lineHeight='162%' fontFamily='lato' fontSize='16px' textAlign="center" sx={{ mt: 1 }}>
                        {msg||"You’ve successfully changed your password!"}
                    </Typography>
                    <Typography textAlign='center'><Button sx={{ mt: 3 }} variant="contained" style={{
                        backgroundImage: 'linear-gradient(to bottom right, rgba(142, 45, 226, 1), rgba(74, 0, 224, 1))',letterSpacing:"3.887px"
                    }} onClick={backToLogin}>{btnMsg||"Back To Login"}</Button></Typography>

                </Box>
            </Modal>
        </div>
    );
}