import React from 'react'

const Footer = () => {
  return (
    <div style={{width:"100%",height:"5vh",backgroundColor:"#C0A2D9",padding:"0px 10%",color:"black",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"20px"}}>
        2023 quizwall.io | All Rights Reserved
    </div>
  )
}

export default Footer