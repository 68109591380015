import { Button, Card,CircularProgress,TextField, Typography,InputLabel } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Style from "../../styles/login.module.scss"
import { Link, useNavigate } from 'react-router-dom'
import SnackUI from '../../common/SnackUI';
import { useDispatch, useSelector } from 'react-redux'
import { registerUserAction } from '../../redux/toolkit/auth/registerUser'
import Welcome from '../../assets/img/welcome.png'
import  Visibility  from '../../assets/img/eye-closed.png';
import  VisibilityOff  from '../../assets/img/eye.png';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {app} from '../../services/fribase'
import Popup from './Popup'
import Logo from '../../assets/logo/favicon.png'





import InputAdornment from '@material-ui/core/InputAdornment';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';



export default function Register() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [c_password, setCPassword] = useState("")
  const [snack, setSnack] = useState(false)
  const [show,setShow]=useState(false)
  const [show2,setShow2]=useState(false)
  const [popup,setPopup]=useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true);


  const registerSelector = useSelector(state => state.registerUser)
  const { message, status, loading } = registerSelector
  const provider = new GoogleAuthProvider();


  

  

  const handleRegister = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const checkEmail=emailRegex.test(email)
    if(!checkEmail){
      return alert("Please Enter Valid Email");
    }

    
    const registerData = await dispatch(registerUserAction({ name,email, password,c_password }))
    
    if (registerData?.payload?.status == 404) {
      // setTimeout(() => {
      //   navigate('/login')
      // }, 3000)
      //message = "Your account has been created succussfully, Please login now at login screen"
    }
    if (registerData?.payload?.status == 200) {
      // message="your account created"
      setPopup(true)
      //message = "Your account has been created succussfully, Please login now at login screen"
    }
    setSnack(true)
  }
  const showPassword=()=>{
    console.log("showPassword ")
        setShow(!show)
  }
  const showPassword2=()=>{
        setShow2(!show2)

  }



//   const handleLogin = async () => {

        
  
//     const auth = getAuth(app);
//     const response = await signInWithPopup(auth, provider);
//     const user = response?.user;
//     const { email} = user;
    
//     try {

        

        
//         setEmail(email)

          
//     } catch (error) {
//         console.log("error");
//     }
    

    
// };


  return (
    <>
    <Box className={Style.login}>
      <div className={Style.first}>
      <img className={Style.img} src={Welcome} height="50vh" alt="welcome"/>
      <div className='logo__name' style={{ display:"flex",justifyContent:"center",position: "absolute", zIndex: "7", top: "100px", left: "7%",cursor:"pointer" }}>
                <a href='https://www.quizwall.io/' target='_blank'><img src={Logo} width="50px" height="50px" alt="quizwall_logo" /> </a>
                <a style={{textDecoration:"none"}}href='https://www.quizwall.io/' target='_blank'><h2 style={{color:"white"}}>Quizwall</h2></a>
               
            </div>
      <aside style={{position:"fixed",zIndex:"4",bottom:"25px",left:"6%"}}>
            <nav style={{position:"fixed",zIndex:"5",bottom:"50px",left:"4%",display:"flex",flexDirection:"row",alignItems:"center",gap:"5px",color:"#fff"}}>
            <p style={{cursor:"pointer"}} onClick={()=>{navigate('/term-of-use')}}>Terms of Use</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/privacy')}}>Privacy Policy</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/cookie')}}>Cookie Policy</p>
            </nav>
              <p style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>GamingAce Technologies Pvt. Ltd.</p>
          </aside>

      </div>
      <div className={Style.second}>
      {/* <Card className={Style.login_card} sx={{ p: 3 }}> */}
        <Stack spacing={2}>
          <Typography variant='h3'  align='left' fontWeight="800" color='#585858'>Welcome</Typography>  
          <Typography sx={{ fontSize: "14px",fontWeight:"400!important" }} textTransform="capitalize"  align='left' >Welcome to quizwall publisher panel please fill in your credentials to get started</Typography>
        </Stack>
        <Box sx={{mt:2}}> 
          <Stack spacing={2}>

          
          <label fontFamily='Lato'  style={{marginBottom:"-10px"}}>NAME</label>
                  <input
                    type='text' style={{height:"36px",borderRadius:"5px",padding:"10px"}} value={name} placeholder='Please Enter Your Name' onChange={(e) => setName(e.target.value)} variant='outlined'                  
                  />

          <label fontFamily='Lato'  style={{marginBottom:"-10px"}}>EMAIL</label>
                  <input
                    type='email' style={{height:"36px",borderRadius:"5px",padding:"10px"}} value={email} placeholder='Please Enter Your Email' /*onClick={handleLogin}*/ onChange={(e) => setEmail(e.target.value)} variant='outlined'                  
                  />  

          <label fontFamily='Lato'  style={{marginBottom:"-10px",}}>Password</label>
              <div  style={{position: "relative", display: "inline-block"}}>
                  <input
                    type={show?"text":"password"} style={{height:"36px",borderRadius:"5px",padding:"10px",width:"100%"}} placeholder='Please Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}                 
                  />
                  <span style={{position: "absolute", top: "6px", right:"10px", cursor: "pointer"}}  onClick={showPassword}>{show?<img src={VisibilityOff} width="23px"/>:<img src={Visibility} width="23px"/>}</span>
              </div>

          <label fontFamily='Lato'  style={{marginBottom:"-10px"}}>Confirm Password</label>
        <div style={{position: "relative", display: "inline-block"}}>
                  <input
                    type={show2?"text":"password"} placeholder='Please Re-Enter Your Password' style={{height:"36px",borderRadius:"5px",padding:"10px",width:"100%"}} value={c_password} onChange={(e) => setCPassword(e.target.value)} variant='outlined'                 
                  />
                  <span style={{position: "absolute", top: "6px", right:"10px", cursor: "pointer"}} onClick={showPassword2}>{show2?<img src={VisibilityOff} width="23px"/>:<img src={Visibility} width="23px"/>}</span>

        </div>



            {/* <TextField type='email' value={email} onChange={(e) => setEmail(e.target.value)} variant='outlined' placeholder='Email' label='Email' /> */}
            {/* <TextField type='password' value={password} onChange={(e) => setPassword(e.target.value)} variant='outlined' placeholder='Password' label='Password' /> */}
            {/* <TextField type='password' value={c_password} onChange={(e) => setCPassword(e.target.value)} variant='outlined' placeholder='Confirm Password' label='Confirm Password' /> */}
            <Link to='/forgetpassword' style={{textDecoration:"none",marginBottom:"10px"}}><Typography variant='subtitle2' align='right' color="#585858">{`FORGOT PASSWORD`}</Typography></Link>
            <Button disabled={name&&email&&password&&c_password?false:true}  onClick={handleRegister} sx={{backgroundColor:"rgba(142, 45, 226, 1)",letterSpacing:"3.887px"}} variant='contained'>SIGN UP</Button>

            <div style={{display:"flex",margin:"30px 0px"}}>
                <div style={{height:"1px",width:"49%",backgroundColor:"#000"}}></div>
                  <p style={{margin:"-10px 5px"}}>OR</p>
                
                  <div style={{height:"1px",width:"49%",backgroundColor:"#000"}}></div>

              </div>
              <Typography color="#585858" textTransform='uppercase' fontFamily="lato" variant='subtitle2' align='center'>{`Already a partner ?`}</Typography>

              <Button sx={{ padding:"0px",border: "3px solid rgba(142, 45, 226, 1)",color:"rgba(142, 45, 226, 1)",letterSpacing:"3.887px" }} disabled={loading}><Link style={{textDecoration:"none",width:"100%"}} to='/login' >{loading ? <CircularProgress /> : 'LOGIN'}</Link></Button>

        
          </Stack>
        </Box>
      {/* </Card> */}
      </div>
    </Box>
    {snack && status==404 && <SnackUI state={snack} setState={setSnack} status={status} message={message} />}
    {popup && status==200 && <Popup state={popup} setState={setPopup} status={status} btnMsg={"Ok"} heading={message} msg={"Your publisher account has been successfully created. Please wait for account verification. You will receive a confirmation email from Quizwall once verified. Thank you."} />}
    </>
    )
}
