import { Button, Card,CircularProgress,TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Style from "../../styles/login.module.scss"
import { Link, useNavigate } from 'react-router-dom'
import SnackUI from '../../common/SnackUI';
import { useDispatch, useSelector } from 'react-redux'
import { registerUserAction } from '../../redux/toolkit/auth/registerUser'
import { resetPasswordAction } from '../../redux/toolkit/auth/resetPassword'
import Welcome from '../../assets/img/forgotpassword.png'
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import  Visibility  from '../../assets/img/eye-closed.png';
import  VisibilityOff  from '../../assets/img/eye.png';
import Blur from '../../assets/img/blur.png'
import Popup from './Popup'



export default function ChangePassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")
  const [snack, setSnack] = useState(false)
  const [show,setShow]=useState(false)
  const [show2,setShow2]=useState(false)


  const registerSelector = useSelector(state => state.resetpassword)
  const { message, status, loading } = registerSelector


  

  const changePasswordHandler = async () => {
    const registerData = await dispatch(resetPasswordAction({password}))
    // console.log("registerData", registerData)
    // if (registerData?.payload?.status == 200) {
    //   setTimeout(() => {
    //     navigate('/login')
    //   }, 3000)
    //   //message = "Your account has been created succussfully, Please login now at login screen"
    // }
    setSnack(true)
  }
useEffect(()=>{
    if(snack){
      // document.getElementById('bg').style.backgroundColor = 'rgba(174, 125, 172, 0.8)';
      const divElement=document.getElementById('bg');

      divElement.style.display='none'
      
        const imageUrl = Blur; // Replace with the actual path to your image
        divElement.style.backgroundImage = `url('${imageUrl}')`;
        divElement.style.backgroundPosition = 'center';
        divElement.style.backgroundSize = 'cover';
        divElement.style.width = '100vw';
        divElement.style.height = '100vh';

        const newDiv = document.createElement('div');

// Set the styles for the new div
newDiv.style.width = '100vw';
newDiv.style.height = '100vh';
newDiv.style.backgroundColor = 'lightgray';
newDiv.style.backgroundImage = `url('${imageUrl}')`; // Replace with the actual path to your image
newDiv.style.backgroundSize = 'cover';
newDiv.style.backgroundPosition = 'center';


// Append the new div to the container
const container = document.getElementById('container');
container.appendChild(newDiv);

      
    }
},[snack])
  const showPassword=()=>{
    console.log("showPassword ")
        setShow(!show)
  }
  const showPassword2=()=>{
        setShow2(!show2)

  }

  return (
    <>
    <div id="container">
    <Box className={Style.login} id="bg">
      <div className={Style.first}>
      <img className={Style.img} src={Welcome} height="50vh" alt="welcome"/>
      <aside style={{position:"fixed",zIndex:"4",bottom:"25px",left:"6%"}}>
            <nav style={{position:"fixed",zIndex:"5",bottom:"50px",left:"4%",display:"flex",flexDirection:"row",alignItems:"center",gap:"5px",color:"#fff"}}>
              <p>Terms of Use</p>|
              <p>Privacy Policy</p>|
              <p>Cookie Policy</p>
            </nav>
              <p style={{textAlign:"center",color:"#fff",fontWeight:"bold"}}>GamingAce Technologies Pvt. Ltd.</p>
          </aside>

      </div>
      <div className={Style.second}>
      {/* <Card className={Style.login_card} sx={{ p: 3 }}> */}
        <Stack spacing={2}>
          <Typography variant='h3'  align='left'  fontWeight="600" color='#585858'>Forgot password?</Typography>  
          <Typography sx={{ fontSize: "14px" }} align='left' >Don’t worry.  We can help.</Typography>
        </Stack>
        <Box sx={{mt:2}}> 
          <Stack spacing={2}>
            {/* <TextField type='password' value={password} onChange={(e) => setPassword(e.target.value)} variant='outlined' placeholder='ENTER NEW PASSWORD' label='ENTER NEW PASSWORD' />
            <TextField type='password' value={cpassword} onChange={(e) => setCPassword(e.target.value)} variant='outlined' placeholder='CONFIRM PASSWORD' label='CONFIRM PASSWORD' /> */}

<label fontFamily='Lato'  style={{marginBottom:"-10px",}}>Enter New Password</label>
              <div  style={{position: "relative", display: "inline-block"}}>
                  <input
                    type={show?"text":"password"} style={{height:"36px",borderRadius:"5px",padding:"10px",width:"100%"}} value={password} onChange={(e) => setPassword(e.target.value)}                 
                  />
                  <span style={{position: "absolute", top: "6px", right:"10px", cursor: "pointer"}} onClick={showPassword}>{show?<img src={VisibilityOff} width="23px"/>:<img src={Visibility} width="23px"/>}</span>
              </div>

          <label fontFamily='Lato'  style={{marginBottom:"-10px"}}>Confirm Password</label>
        <div style={{position: "relative", display: "inline-block"}}>
                  <input
                    type={show2?"text":"password"} style={{height:"36px",borderRadius:"5px",padding:"10px",width:"100%"}} value={cpassword} onChange={(e) => setCPassword(e.target.value)} variant='outlined'                 
                  />
                  <span style={{position: "absolute", top: "6px", right:"10px", cursor: "pointer"}} onClick={showPassword2}>{show2?<img src={VisibilityOff} width="23px"/>:<img src={Visibility} width="23px"/>}</span>

        </div>
            
                <Button onClick={changePasswordHandler} sx={{backgroundColor:"rgba(142, 45, 226, 1)",letterSpacing:"3.877px"}} variant='contained'>SAVE PASSWORD</Button>
           <Stack>
            <Typography marginTop="18%" color="#585858" fontFamily="lato" fontSize="21px" variant='subtitle2' align='right'>{`Remembered your Password?`}</Typography>

            <Typography variant='h6' sx={{color:"rgba(142, 45, 226, 1)",fontSize:"1.8em" }} align='right' disabled={loading}><Link style={{textDecoration:"none",width:"100%"}} to='/login' >{loading ? <CircularProgress /> : 'Back To Login'}</Link></Typography>
            </Stack>

        
          </Stack>
        </Box>
      {/* </Card> */}
      </div>
    </Box>
    </div>
    {/* {snack && <SnackUI state={snack} setState={setSnack} status={status} message={message} />} */}
    {snack && <Popup state={snack} setState={setSnack} />}
    </>
    )
}
