export const dateFormatStartFun = (startOfToday) => {
    if(startOfToday==undefined)
       return undefined
    const startDate=new Date(startOfToday?.$d)
    startDate.setHours(startDate.getHours() + 5);
    startDate.setMinutes(startDate.getMinutes() + 30);
    // const startDate = moment(startOfToday.$d).format();
    console.log("startDate===>",startDate.toISOString())
    return startDate.toISOString()
}

export const dateFormatEndFun = (endOfToday) => {

    const endDate=new Date(endOfToday?.$d).toISOString()
    return endDate

}