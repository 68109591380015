import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"


const initialState = { status: null, loading: false, data: [], error: "", message: '' }


export const noOfParticipantsin30DaysAction = createAsyncThunk(
    'total-participants-in-last-30days',
    async (data, { rejectWithValue }) => {
        try {
            console.log("data", data)
            // const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/no-of-quit-attempts`)
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/total-participants-in-last-30days/${data.date}/${data.days}`)

            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const noOfParticipantsin30DaysSlice = createSlice({
    name: 'total-participants-in-last-30days',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(noOfParticipantsin30DaysAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(noOfParticipantsin30DaysAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(noOfParticipantsin30DaysAction.rejected, (state, action) => {
                state.loading = false
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.error = action?.payload?.message
            })
        },

})



export const  noOfParticipantsin30DaysReducer = noOfParticipantsin30DaysSlice.reducer