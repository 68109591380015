
import { Button, Card, CircularProgress, TextField, Typography } from '@mui/material';
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputLabel, OutlinedInput } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from "../../assets/img/logo.png";
import Welcome from '../../assets/img/welcome.png'
import SnackUI from '../../common/SnackUI';
import { loginUserAction } from '../../redux/toolkit/auth/loginUser';
import Style from "../../styles/login.module.scss";
import LoadingUI from '../../common/LoadingUI';
import Visibility from '../../assets/img/eye-closed.png';
import VisibilityOff from '../../assets/img/eye.png';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from '../../services/fribase'
import Logo from '../../assets/logo/favicon.png'



export default function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [snack, setSnack] = useState(false)
  const [showPassword, setShowPassword] = useState(false)



  const loginSelector = useSelector(state => state.loginUser)
  const { message, status, loading } = loginSelector
  const provider = new GoogleAuthProvider();



  const handleSubmitLogin = async () => {
    const loginData = await dispatch(loginUserAction({ email, password }))
    console.log("loginData", loginData)
    if (loginData?.payload?.status == 200) {
      localStorage.setItem("auth_token", loginData?.payload.token)
      navigate("/")
      window.location.reload()

    }
    setSnack(true)
  }

  // const handleLogin = async () => {



  //   const auth = getAuth(app);
  //   const response = await signInWithPopup(auth, provider);
  //   const user = response?.user;
  //   const { email } = user;

  //   try {


  //     setEmail(email)


  //   } catch (error) {
  //     console.log("error");
  //   }



  // };

  return (
    <>
      <Box className={Style.login}>
        <div className={Style.first}>
            
          <img className={Style.img} src={Welcome} height="50vh" alt="" srcset="" />
          <div className='logo__name' style={{ display:"flex",justifyContent:"center",position: "absolute", zIndex: "7", top: "100px", left: "7%",cursor:"pointer" }}>
                <a href='https://www.quizwall.io/' target='_blank'><img src={Logo} width="50px" height="50px" alt="quizwall_logo" /> </a>
                <a style={{textDecoration:"none"}}href='https://www.quizwall.io/' target='_blank'><h2 style={{color:"white"}}>Quizwall</h2></a>
               
            </div>
          <aside style={{ position: "fixed", zIndex: "4", bottom: "25px", left: "6%" }}>
            <nav style={{ position: "fixed", zIndex: "5", bottom: "50px", left: "4%", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", color: "#fff" }}>
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/term-of-use')}}>Terms of Use</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/privacy')}}>Privacy Policy</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/cookie')}}>Cookie Policy</p>
            </nav>
            <p style={{ textAlign: "center", color: "#fff", fontWeight: "bold" }}>GamingAce Technologies Pvt. Ltd.</p>
          </aside>
        </div>
        <div className={Style.second}>
          {/* <Card className={Style.login_card} sx={{ p: 3 }}> */}
          <Box className={Style.brand}>
            {/* <img style={{ minWidth: "1px" }} src={Logo} alt='logo' />  */}
          </Box>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography className={Style.text_shadow} variant='h4' color='585858' sx={{
              color: "#585858",
              fontFeatureSettings: `'clig' off, 'liga' off`,
              fontFamily: "Lato",
              fontSize: "37px",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "138.5%"
            }}>Welcome Back</Typography>
            <Typography sx={{ fontSize: "14px" }} className={Style.welcome_sub_heading}>Welcome to quizwall publisher panel please fill in your credentials to get started</Typography>
          </Stack>
          <Box sx={{ mt: 6 }}>
            <Stack spacing={2}>



              <label style={{ marginBottom: "-10px", fontSize: "14px" }}>EMAIL</label>
              <input
                id="my-text-field"
                type='email' placeholder="Please Enter Your Email" value={email} /*onClick={handleLogin}*/ onChange={(e) => setEmail(e.target.value)}
                style={{ height: "36px", borderRadius: "5px", padding: "10px" }}



              />

              <label fontFamily='Lato' style={{ marginBottom: "-10px", fontSize: "14px" }}>PASSWORD</label>
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  type={showPassword ? "text" : "password"} placeholder="Please Enter Password" style={{ height: "36px", borderRadius: "5px", padding: "10px", width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)}
                />
                <span style={{ position: "absolute", top: "6px", right: "10px", cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <img src={VisibilityOff} width="23px" /> : <img src={Visibility} width="23px" />}</span>
              </div>

              {/* <label htmlFor="my-text-field2" sx={{marginBottom:"-10px !important",marginTop:"10px"}}>PASSWORD</label>
              <FormControl variant="outlined">
                <OutlinedInput

                  type={showPassword ? 'text' : 'password'}
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                
                />
              </FormControl> */}

              <Typography variant='subtitle2' align='right' fontSize="14px" color="#585858"><Link to='/forgetpassword' style={{ textDecoration: "none", marginBottom: "10px" }}>{`FORGOT PASSWORD`}</Link></Typography>
              {/* <Link to='/register' ><Typography variant='subtitle2' align='center'>{`Don't have an account`}</Typography></Link> */}
              <Button sx={{ backgroundColor: "rgba(142, 45, 226, 1)", letterSpacing: "3.887px" }} disabled={loading} onClick={handleSubmitLogin} variant='contained'>{loading ? <CircularProgress /> : 'Login'}</Button>
              <div style={{ display: "flex", margin: "30px 0px" }}>
                <div style={{ height: "1px", width: "49%", backgroundColor: "#000" }}></div>
                <p style={{ margin: "-10px 5px" }}>OR</p>

                <div style={{ height: "1px", width: "49%", backgroundColor: "#000" }}></div>

              </div>
              <Typography color="585858" fontFamily="lato" textTransform='uppercase' variant='subtitle2' align='center'>{`Become a quizwall partner`}</Typography>

              <Button sx={{padding:"0px",border: "3px solid rgba(142, 45, 226, 1)", color: "rgba(142, 45, 226, 1)" }} disabled={loading}><Link style={{ textDecoration: "none", width: "100%",letterSpacing: "3.887px" }} to='/register' >{loading ? <CircularProgress /> : 'SIGN UP'}</Link></Button>


            </Stack>
          </Box>
          {/* </Card> */}
        </div>
        <div className={Style.third}>
        <nav style={{ zIndex: "5", bottom: "50px", left: "4%", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", }}>
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/term-of-use')}}>Terms of Use</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/privacy')}}>Privacy Policy</p>|
              <p style={{cursor:"pointer"}} onClick={()=>{navigate('/cookie')}}>Cookie Policy</p>
            </nav>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>GamingAce Technologies Pvt. Ltd.</p>

        </div>
      </Box>

      {snack && <SnackUI state={snack} setState={setSnack} status={status} message={message} />}
    </>
  )
}
